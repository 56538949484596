import PropTypes from 'prop-types';
import { useState, useEffect, createElement } from 'react';
import { Link } from 'react-router-dom';

import KpiContainer from "../KpiContainer";
import { useGetChartHeight } from "utils/hooks";
import { formatAmount } from "utils/common";
import withKpiData from "utils/WithKpiData";


import Chart from 'react-apexcharts';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography } from '@mui/material';

// project imports
import { CardWrapper, StyledButton } from './CardChartStyled';
import CardChartSkeleton from './Skeleton/CardChartSkeleton';


// assets
import * as MuiIcons from "@mui/icons-material";
const COLORS_MODE = ["BLUE", "GREEN", "PURPLE"];


const getChartData = (card) => {
    const title = card.kpi_title;
    let data = [];
    if (card?.series?.data) {
        if (card.series.data[0] && card.series.data[0].hasOwnProperty('y')) {
            data = card.series.data.map((item) => item.y);
        }
        else {
            data = card.series.data;
        }
    }

    return {
        type: 'line',
        height: 90,
        options: {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#fff'],
            fill: {
                type: 'solid',
                opacity: 1
            },
            stroke: {
                curve: 'smooth',
                width: 3
            },
            yaxis: {
                min: Math.min(...data),
                max: Math.max(...data)
            },
            tooltip: {
                theme: 'dark',
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return '<div><ul>' +
                        '<li><b>' + series[seriesIndex][dataPointIndex] + '</b></li>' +
                        '<li><b>' + card.xAxis[dataPointIndex] + '</b></li>' +
                        '</ul></div>';
                },
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: title,
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return '<ul>' +
                            '<li><b>' + card.xAxis[dataPointIndex] + '</b></li>' +
                            '<li><b>' + value + '</b></li>' +
                            '</ul>';
                    }
                },
                marker: {
                    show: false
                }
            }
        },
        series: [
            {
                name: title,
                data: data
            }
        ]
    };
}



const ChartCard = ({ isLoading, data, gridInformation, requestHandler: fetchCardChatData }) => {
    //This is the ChartCard component.It is used to display a chart on the dashboard.
    //It is used in the Dashboard component.
    const theme = useTheme();
    const [selectedOption, setSelectedOption] = useState(null);
    const [childRef, chartHeight] = useGetChartHeight(gridInformation);

    useEffect(() => {
        if (data && data.switch_options) {
            setSelectedOption(
                data.switch_options.find((el) => el.selected === true)
                    ? data.switch_options.find((el) => el.selected === true).value
                    : null
            );
        }
    }, []);

    const requestHandler = (switch_option) => {
        const apiPayload = {
            period: switch_option ? switch_option : selectedOption,
        };
        fetchCardChatData(undefined, apiPayload);
    };


    const handleOptionChange = (value) => {
        setSelectedOption(value);
        requestHandler(value);
    };

    const cardChartData = data?.card ? getChartData(data?.card) : {}
    const kpiColorData = COLORS_MODE.includes(data?.card?.kpi_color) ? data?.card?.kpi_color : COLORS_MODE[1];
    const kpiColor = theme.palette['berry_' + kpiColorData.toLowerCase() + '_primary'];
    const kpiAmount = formatAmount(data?.card?.kpi_amount);

    const getCardContent = () => {
        return <CardWrapper
          border={false}
          content={false}
          color={kpiColor}
          height={chartHeight == 0 ? null : chartHeight + "px"}
        >
          <Box sx={{ p: 2.25 }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Avatar
                      variant="rounded"
                      style={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor:
                          kpiColor?.[800] ??
                          theme.palette.berry_green_primary[800],
                        color: "#fff",
                        mt: 1,
                      }}
                    >
                      {createElement(MuiIcons[data.card.kpi_icon])}
                    </Avatar>
                  </Grid>
                  {data && data.switch_options ? (
                    <Grid item>
                      {data.switch_options.map((item, index) => (
                        <StyledButton
                          variant={item.selected ? "contained" : "text"}
                          size="small"
                          selected={item.selected}
                          kpicolor={kpiColor}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault(); // Add this to prevent link activation
                            handleOptionChange(item.value)
                          }}
                        >
                          {item.label}
                        </StyledButton>
                      ))}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 0.75 }}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 500,
                            mr: 1,
                            mt: 1.75,
                            mb: 0.75,
                          }}
                        >
                          {kpiAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          style={{
                            fontSize: "1rem",
                            fontWeight: 500,
                            color:
                              kpiColor?.[200] ??
                              theme.palette.berry_green_primary[200],
                          }}
                        >
                          {data?.card?.kpi_title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Chart {...cardChartData} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
        };
    return <KpiContainer refPaper={true} ref={childRef}>
              {gridInformation?.link ? (
                <Link to={gridInformation.link} style={{ textDecoration: "none" }}
                  onClick={(e) => {
                    if (e.target.closest('button')) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}>
                  {getCardContent()}
                </Link>
              ) : (
                getCardContent()
              )}
    </KpiContainer>
              
};

export default withKpiData(ChartCard, CardChartSkeleton);